import styled from 'styled-components'
import Flickity from 'react-flickity-component'

export const StyledFlickityImageComponent = styled(Flickity)<{$height?: number}>`
  width: 100%;
  max-width: ${props => props.theme.typography.pxToRem(798)};
  outline: 0;
  position: relative;

  .flickity-button {
    display: none;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    .flickity-button {
      display: block;
      position: absolute;
      background: hsla(0, 0%, 100%, 0.75);
      border: none;
      color: ${props=> props.theme.palette.text.primary};

      &:hover {
        cursor: pointer;

        > .flickity-button-icon {
          fill: ${props=> props.theme.palette.primary.main};
        }
      }
    }

    .flickity-prev-next-button {
      top: 50%;
      width: ${props => props.theme.typography.pxToRem(84)};
      height: ${props => props.theme.typography.pxToRem(84)};
      padding: ${props => props.theme.typography.pxToRem(28)};
      border-radius: 50%;
      box-shadow: 0 ${props => props.theme.typography.pxToRem(3)} ${props => props.theme.typography.pxToRem(15)} ${props => props.theme.palette.text.disabled};
      background: ${props => props.theme.palette.background.default};

      &:hover {
        cursor: pointer;
      }
    }

    .previous {
      left: 0;
      transform: translate(-50%, -50%);
    }

    .next {
      right: 0;
      transform: translate(50%, -50%);
    }
  }

  .flickity-viewport {
    overflow-x: hidden;
    overflow-y: hidden;
    height: ${props => props.theme.typography.pxToRem(props.$height)} !important;

    &:focus {
      outline: 0 !important;
    }
  }
`

export const StyledFlickityContentComponent = styled(Flickity)<{$height?: number}>`
  width: 100%;
  outline: 0;
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  ${props => props.theme.breakpoints.up('sm')} {
    flex-basis: 40%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .flickity-button {
    display: none;
  }

  .flickity-viewport {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    height: ${props => props.theme.typography.pxToRem(props.$height)} !important;

    &:focus {
      outline: 0 !important;
    }

    .flickity-slider {
      height: 100%;
      ${props => props.theme.breakpoints.up('sm')} {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .flickity-page-dots {
    position: relative;
    left: 0;
    padding: 0;
    margin: ${props => props.theme.typography.pxToRem(31)} 0 0 0;
    list-style: none;
    text-align: center;
    line-height: 1;

    ${props => props.theme.breakpoints.up('sm')} {
      bottom: ${props => props.theme.typography.pxToRem(50)};
      margin: 0;
    }

    li {
      margin: 0;

      &::before {
        display: none;
      }
    }

    .dot {
      display: inline-block;
      width: ${props => props.theme.typography.pxToRem(10)};
      height: ${props => props.theme.typography.pxToRem(10)};
      margin: 0 ${props => props.theme.typography.pxToRem(8)};
      background: #333;
      border-radius: 50%;
      opacity: 0.25;
      cursor: pointer;

      &.is-selected {
        opacity: 1;
        color: ${props => props.theme.palette.primary.main};
        background: ${props => props.theme.palette.primary.main};
      }
    }
  }
`
import React from 'react'
import { gql } from '@apollo/client'
import { BannerFragment } from './__generated__/banner'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { HomeBanners } from '../../../components/banner/HomeBanners'
import { HomeBanner } from '../../../components/banner/HomeBanner'
import { html } from '../../../utils/html'

export type BannerProps = {
  data: BannerFragment[]
}

export const Banner: React.VFC<BannerProps> = ({ data }) => {
  return (
    <HomeBanners>
      {data.map((banner, i) => (
        <HomeBanner key={i} image src={banner.bannerImage[0].url}>
          <HomeBanner title text={banner.bannerText}/>
          <HomeBanner subtitle text={html(banner.bannerSubText)}/>
          <HomeBanner cta href={banner.bannerButtonLink} text={banner.bannerButtonText}/>
        </HomeBanner>
      ))}
    </HomeBanners>
  )
}

export const BANNER_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment BannerFragment on bannerHome_BlockType {
    bannerText
    bannerSubText
    bannerButtonText
    bannerButtonLink
    bannerImage {
      ...ImageFragment
    }
  }
`

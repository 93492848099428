import React from 'react'
import { gql } from '@apollo/client'
import { QuickLinksFragment } from './__generated__/quickLinks'
import { QuickLinks as QuickLinksComponent } from '../../../components/QuickLinks/QuickLinks'

export type QuickLinksProps = {
  data: QuickLinksFragment[]
}

export const QuickLinks: React.FC<QuickLinksProps> = ({ data }) => {
  return (
    <QuickLinksComponent>
      {data.map((quickLink, i) => (quickLink.__typename !== 'homePageQuickLinks_BlockType' ? null :         <QuickLinksComponent key={i} card iconCode={quickLink.icon} title={quickLink.quickLinkTitle} href={quickLink.href}>
        {quickLink.quickLinks.map((subLink, i) => (
          <QuickLinksComponent key={i} item href={subLink.href}>
            {subLink.text}
          </QuickLinksComponent>
        ))}
      </QuickLinksComponent>
      ))}
    </QuickLinksComponent>
  )
}

export const QUICK_LINKS_FRAGMENT = gql`
  fragment QuickLinksFragment on homePageQuickLinks_BlockType {
    icon
    href
    quickLinkTitle
    quickLinks {
      ... on quickLinks_quickLink_BlockType {
        text
        href
      }
    }
  }
`
import React from 'react'
import { gql } from '@apollo/client'
import { HomepageQuery } from './__generated__'
import {
  Banner,
  BANNER_FRAGMENT,
  COLUMN_FEATURE_FRAGMENT,
  ColumnFeature,
  FULL_SCREEN_CTA_FRAGMENT,
  FullScreenCta,
  News,
  NEWS_FRAGMENT,
  Offers,
  OFFERS_FRAGMENT,
  QUICK_LINKS_FRAGMENT,
  QuickLinks,
  RELATED_ARTICLES_FRAGMENT,
  RelatedArticles,
  SIMPLE_CTA_FRAGMENT,
  SimpleCta,
} from './fragments'
import { Container, Grid } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { rem } from '../../utils/rem'

export type HomeProps = HomepageQuery & {
  //
}

const StyledContainer = styled(Grid)`
  > section {
    width: 100%;
  }
`

const slantedStyles = css`
  margin-top: ${props => rem(40, props.theme)};
  padding-top: ${props => rem(100, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-top: ${props => rem(120, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding-top: ${props => rem(150, props.theme)};
  }

  &::before {
    bottom: ${props => rem(20, props.theme)};
    clip-path: polygon(0 30px, 100% 0, 100% 100%, 0 calc(100% - 80px));

    ${props => props.theme.breakpoints.up('sm')} {
      clip-path: polygon(0 80px, 100% 0, 100% 100%, 0 calc(100% - 120px));
    }

    ${props => props.theme.breakpoints.up('md')} {
      top: ${props => rem(-50, props.theme)};
      clip-path: polygon(0 150px, 100% 0, 100% 100%, 0 calc(100% - 150px));
    }
  }
`

const flatStyles = css`
  margin: ${props => rem(64, props.theme)} 0 ${props => rem(85, props.theme)};
  padding: ${props => rem(71, props.theme)} 0 ${props => rem(64, props.theme)};

  ${props => props.theme.breakpoints.up('md')} {
    margin: ${props => rem(110, props.theme)} 0 ${props => rem(157, props.theme)};
    padding: ${props => rem(190, props.theme)} 0 ${props => rem(157, props.theme)};
  }
`

export const StyledAltBgSection = styled.section<{ $slanted?: boolean }>`
  position: relative;

  &::before {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f2f2f2;
    position: absolute;
    z-index: -1;
  }

  ${props => props.$slanted ? slantedStyles : flatStyles}
`

const StyledSimpleCtaWrapper = styled(Grid)`
  margin-top: ${props => rem(70, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(120, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => rem(160, props.theme)};
  }
`

const StyledRelatedArticlesContainer = styled(Grid)`
  margin: ${props => rem(94, props.theme)} 0 0;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(124, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => rem(154, props.theme)};
  }
`

const StyledFullScreenCtaContainer = styled(Grid)`
  margin: ${props => rem(83, props.theme)} 0 ${props => rem(75, props.theme)};

  ${props => props.theme.breakpoints.up('md')} {
    margin: ${props => rem(155, props.theme)} 0;
  }
`

export const Home: React.FC<HomeProps> = ({ entry }) => {
  // We know this is always a homepage, but technically GraphQL says this could be any entry, so we must first check
  if (entry.__typename !== 'homePage_homePage_Entry') {
    return null
  }

  return (
    <>
      <Banner data={entry.bannerHome} />
      <StyledAltBgSection $slanted>
        <Container>
          <StyledContainer direction={'column'} alignItems={'center'} container>
            <Grid item lg={10} component={'section'}>
              <QuickLinks data={entry.homePageQuickLinks} />
            </Grid>
            {/** @ts-ignore **/}
            <StyledSimpleCtaWrapper item lg={10} component={'section'}>
              <SimpleCta data={entry.simpleCta} />
            </StyledSimpleCtaWrapper>
          </StyledContainer>
        </Container>
      </StyledAltBgSection>
      <Container>
        <StyledContainer direction={'column'} alignItems={'center'} container>
          {/** @ts-ignore **/}
          <StyledRelatedArticlesContainer item lg={12} component={'section'}>
            <RelatedArticles data={entry.relatedArticles} />
          </StyledRelatedArticlesContainer>
        </StyledContainer>
      </Container>
      <StyledAltBgSection>
        <Container>
          <StyledContainer direction={'column'} alignItems={'center'} container>
            <Grid item lg={10} component={'section'}>
              <Offers data={entry.offersPromotions} />
            </Grid>
          </StyledContainer>
        </Container>
        {/** @ts-ignore **/}
        <StyledFullScreenCtaContainer component={'section'}>
          <FullScreenCta data={entry.fullScreenCta} />
        </StyledFullScreenCtaContainer>
        <Container>
          <StyledContainer direction={'column'} alignItems={'center'} container>
            <Grid item lg={10} component={'section'}>
              {/** @ts-ignore **/}
              <ColumnFeature data={entry.columnFeature} />
            </Grid>
          </StyledContainer>
        </Container>
      </StyledAltBgSection>
      <Container>
        <StyledContainer direction={'column'} alignItems={'center'} container>
          <Grid item lg={12} component={'section'}>
            <News data={entry.newsArticles} />
          </Grid>
        </StyledContainer>
      </Container>
    </>
  )
}

export const HOMEPAGE_QUERY = gql`
  ${BANNER_FRAGMENT}
  ${QUICK_LINKS_FRAGMENT}
  ${SIMPLE_CTA_FRAGMENT}
  ${RELATED_ARTICLES_FRAGMENT}
  ${OFFERS_FRAGMENT}
  ${FULL_SCREEN_CTA_FRAGMENT}
  ${COLUMN_FEATURE_FRAGMENT}
  ${NEWS_FRAGMENT}
  
  query HomepageQuery($uid: [String]) {
    entry(section: "homePage", uid: $uid) {
      ... on homePage_homePage_Entry {
        bannerHome {
          ...BannerFragment
        }
        homePageQuickLinks {
          ...QuickLinksFragment
        }
        simpleCta {
          ...SimpleCtaFragment
        }
        relatedArticles {
          ...RelatedArticlesHomeFragment
        }
        offersPromotions {
          ...OffersFragment
        }
        fullScreenCta {
          ...FullScreenCtaFragment
        }
        columnFeature {
          ...ColumnFeatureFragment
        }
        newsArticles {
          ...NewsFragment
        }
      }
    }
  }
`
export { StyledHeading } from './styledHeading'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { rem } from '../../utils/rem'

export const StyledHeading = styled(Typography)`
  font-size: ${props => rem(15, props.theme)};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  letter-spacing: ${props => rem(1.5, props.theme)};
  color: ${props => props.theme.palette.primary.dark};
  text-transform: uppercase;
  opacity: 1;
  margin: 0 0 ${props => rem(40, props.theme)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: ${props => rem(58, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: ${props => rem(77, props.theme)};
  }
`
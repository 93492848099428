import React from 'react'
import { Grid } from '@material-ui/core'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { IconCodeType } from '../icon/CreditUnionIcon'
import { CreditUnionIcon } from '../icon/CreditUnionIcon'
import { StyledCardContainer, StyledCardIconContainer, StyledTitleText, StyledTitleCheveron, StyledCardList } from './styledQuickLinksCard'
import Link, { LinkProps } from 'next/link'

export type QuickLinksCardProps =  React.ComponentProps<typeof StyledCardContainer> & Pick<LinkProps, 'href'> & {
  title: string
  iconCode: IconCodeType
}

export const QuickLinksCard: React.FC<QuickLinksCardProps> = ({ title, iconCode, href, children, item=true, xs=12, sm=4, ...props }) => {
  return (
    <StyledCardContainer item={item} xs={xs} sm={sm} {...props}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        <StyledCardIconContainer item>
          <CreditUnionIcon width={49} height={49} iconCode={iconCode}/>
        </StyledCardIconContainer>
        <Grid item>
          <Link href={href} passHref>
            <StyledTitleText rel="nooppener noreferrer" target="_blank">{ title }</StyledTitleText>
          </Link>
          <StyledTitleCheveron icon={faChevronRight}/>
        </Grid>
      </Grid>
      <Grid container>
        <StyledCardList>
          {children}
        </StyledCardList>
      </Grid>
    </StyledCardContainer>
  )
}
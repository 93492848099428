import React, { useRef, useEffect } from 'react'
import Flickity from 'react-flickity-component'
import { StyledFlickityComponent, StyledCarouselContainer } from './styledCardDesignCarousel'
import { useTheme, useMediaQuery } from '@material-ui/core'

export type CardDesignCarouselProps = Omit<React.ComponentProps<typeof Flickity>, 'flickityRef'> & {
  //
}

export const CardDesignCarousel: React.FC<CardDesignCarouselProps> = ({ children, ...props }) => {
  const flickityRef = useRef(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    const groupCells = isMobile ? 1 : (isTablet ? 2 : 3)

    if (flickityRef.current.flkty) {
      flickityRef.current.flkty.options.groupsCells = groupCells
    }
  }, [isMobile, isTablet, flickityRef])

  return (
    <StyledCarouselContainer>
      <StyledFlickityComponent ref={flickityRef} {...props} options={{
        freeScroll: true,
        wrapAround: true,
        contain: true,
        adaptiveHeight: true,
        setGallerySize: false,
        cellAlign: 'left',
      }}>
        {children}
      </StyledFlickityComponent>
    </StyledCarouselContainer>
  )
}

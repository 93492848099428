import React from 'react'
import { gql } from '@apollo/client'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { FullScreenCtaFragment } from './__generated__/fullScreenCta'
import { CTA as CTABase } from '../../../components/cta/cta'
import { Button } from '../../../components/button/button'

export type FullScreenCtaProps = {
  data: FullScreenCtaFragment[]
}

export const FullScreenCta: React.FC<FullScreenCtaProps> = ({ data }) => {
  const { background, buttonText, text, buttonLink } = data[0]

  return (
    <CTABase imageURL={background[0].url}>
      <CTABase content>
        <CTABase heading>
          {text}
        </CTABase>
        <CTABase buttons>
          <Button cta href={buttonLink}>{buttonText}</Button>
        </CTABase>
      </CTABase>
    </CTABase>
  )
}

export const FULL_SCREEN_CTA_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}
  
  fragment FullScreenCtaFragment on fullScreenCta_BlockType {
    text
    buttonText
    buttonLink
    background {
      ...ImageFragment
    }
  }
`
import React, { useMemo } from 'react'
import { useTheme, useMediaQuery } from '@material-ui/core'
import {
  CarouselImage, CarouselImageProps,
  CarouselTitle, CarouselTitleProps,
  CarouselDescription, CarouselDescriptionProps,
  CarouselCta, CarouselCtaProps,
  ContentSection, ContentSectionProps,
  OfferPromotionCarouselSection, OfferPromotionCarouselSectionProps,
  OfferPromotionCarouselWrapper, OfferPromotionCarouselWrapperProps,
} from './CarouselElements'

export type OfferPromotionCarouselProps = ({ image?: boolean; title?: boolean; description?: boolean; cta?: boolean; contentSection?: boolean; flickitySection?: boolean }) & (
  // Image
  | ({ image: true } & CarouselImageProps)
  // Title
  | ({ title: true } & CarouselTitleProps)
  // Description
  | ({ description: true } & CarouselDescriptionProps)
  // CTA
  | ({ cta: true } & CarouselCtaProps)
  // Content section
  | ({ contentSection: true } & ContentSectionProps)
  // Carousel section
  | ({ flickitySection: true } & OfferPromotionCarouselSectionProps)
  // Container
  | OfferPromotionCarouselWrapperProps
)

export const OfferPromotionCarousel: React.FC<OfferPromotionCarouselProps> = ({ image, title, description, cta, contentSection, flickitySection, children, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const layout = useMemo(() => {
    return isMobile ? 'vertical' : 'horizontal'
  }, [isMobile])

  if (image) {
    return (
      <CarouselImage {...props} />
    )
  }

  if (title) {
    return (
      <CarouselTitle {...props} variant="h2">
        {children}
      </CarouselTitle>
    )
  }

  if (description) {
    return (
      <CarouselDescription {...props} variant="body1">
        {children}
      </CarouselDescription>
    )
  }

  if (cta) {
    return (
      <CarouselCta {...props}>
        {children}
      </CarouselCta>
    )
  }

  if (contentSection) {
    return (
      <ContentSection {...props} layout={layout}>
        {children}
      </ContentSection>
    )
  }

  if (flickitySection) {
    return (
      <OfferPromotionCarouselSection {...props} layout={layout}>
        {children}
      </OfferPromotionCarouselSection>
    )
  }

  return (
    <OfferPromotionCarouselWrapper {...props} layout={layout}>
      {children}
    </OfferPromotionCarouselWrapper>
  )
}
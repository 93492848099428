import React from 'react'
import { gql } from '@apollo/client'
import { RelatedArticlesHomeFragment } from './__generated__/relatedArticles'
import { StyledHeading } from '../styledHeading'
import { CardDesignCarousel } from '../../../components/CardDesignCarousel/CardDesignCarousel'
import { CardDesignItem } from '../../../components/CardDesignCarousel/CardDesignItem'

export type RelatedArticlesProps = {
  data: RelatedArticlesHomeFragment[]
}

export const RelatedArticles: React.FC<RelatedArticlesProps> = ({ data }) => {
  return (
    <>
      <StyledHeading variant="h5" align="center">
        Helping you get to where you need to
      </StyledHeading>
      <CardDesignCarousel>
        {data.map((item) => {
          switch (item.__typename) {
          case 'newsLandingPage_newsLandingPage_Entry':
          case 'products_pages_product_detail_Entry':
          case 'products_pages_product_fcp_Entry':
          case 'products_pages_product_listing_Entry':
          case 'faqLandingPage_faqLandingPage_Entry':
          case 'glossaryPage_glossaryPage_Entry':
          case 'search_search_Entry':
              
            return (
              <CardDesignItem key={item.id} href={item.url}>
                {item.relatedContentMeta[0].metaImage[0] && item.relatedContentMeta[0].metaImage[0].__typename === 'images_Asset' && (
                  <CardDesignItem image src={item.relatedContentMeta[0].metaImage[0].url} alt={item.relatedContentMeta[0].metaImage[0].alt} layout="fill" objectFit="cover" objectPosition="center" />
                )}
                <CardDesignItem title>{item.relatedContentMeta[0].metaTitle}</CardDesignItem>
                <CardDesignItem description>{item.relatedContentMeta[0].metaexcerpt}</CardDesignItem>
              </CardDesignItem>
            )
          }
        })}
      </CardDesignCarousel>
    </>
  )
}

export const RELATED_ARTICLES_FRAGMENT = gql`
  fragment RelatedArticlesHomeFragment on EntryInterface {
    ... on newsLandingPage_newsLandingPage_Entry {
      id
      url
      relatedContentMeta {
        ... on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ... on glossaryPage_glossaryPage_Entry {
      id
      url
      relatedContentMeta {
        ... on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ... on products_pages_product_listing_Entry {
      id
      url
      relatedContentMeta {
        ... on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ... on products_pages_product_detail_Entry {
      id
      url
      relatedContentMeta {
        ... on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ... on products_pages_product_fcp_Entry {
      id
      url
      relatedContentMeta {
        ... on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ...on search_search_Entry {
      id
      url
      relatedContentMeta {
        ...on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
    ...on faqLandingPage_faqLandingPage_Entry {
      id
      url
      relatedContentMeta {
        ...on relatedContentMeta_BlockType {
          id
          metaTitle
          metaexcerpt
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
  }
`
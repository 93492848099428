import React from 'react'
import styled from 'styled-components'
import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { theme } from '../../theme'
import Image from 'next/image'

export type CTAWrapperProps = React.ComponentProps<typeof StyledContainer> & {
    imageURL?: string
    imageAlt?: string 
}

export type CTAContentProps = React.ComponentProps<typeof ContentContainer> & {
    //
  }

export type CTAHeadingProps = React.ComponentProps<typeof HeadingContainer> & {
    //
  }
  
export type CTAButtonProps = & React.ComponentProps<typeof CTAButtonContainer> & {
    buttonURL?: string
  }

export type CTAProps =
| (
    {
      heading?: never
      buttons?: never
      content?: never
    } & CTAWrapperProps
  )
  | (
    {
      heading?: never
      buttons: true
      content?: never
    } & CTAButtonProps
  )
  | (
    {
      heading?: never
      buttons?: never
      content: true
    } & CTAContentProps
  )
  | (
    {
      heading: true
      buttons?: never
      content?: never
    } & CTAHeadingProps
  )

const StyledContainer = styled.div`
  position: relative;
  background-color: black;
  background-size: cover;
  width: 100%;
  clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);
  -webkit-backface-visibility: hidden;
  min-height: 70vh;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    z-index: 1;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    min-height: 95vh;

    &::after {
      content: none;
    }
  }
`

const HeadingContainer = styled(Typography)`
  & > span {
    display: block;
    box-shadow: unset;
    background-color: unset;
    font-size: ${props => props.theme.typography.pxToRem(32)};
    color: white;
    text-align: center;
    padding: unset;

    ${props => props.theme.breakpoints.up('sm')} {
      width: unset;
      background-color: white;
      color: unset;
      font-size: ${props => props.theme.typography.pxToRem(60)};
      padding: ${props => props.theme.typography.pxToRem(15)} 0 ${props => props.theme.typography.pxToRem(15)} 0;
      word-wrap: break-word;
      display: inline;
      box-shadow: 40px 0 0 white, -40px 0 0 white;
      margin-bottom: ${props => props.theme.typography.pxToRem(10)};
    }
  }
`

const ContentContainer = styled(Grid)`
  z-index: 2;
  position: absolute;
  max-width: ${props => props.theme.typography.pxToRem(300)};
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  ${props => props.theme.breakpoints.up('sm')} {
    max-width: ${props => props.theme.typography.pxToRem(600)};
  }
`

const CTAButtonContainer = styled(Grid)`
  margin-left: unset;
  margin-top: ${props => props.theme.typography.pxToRem(15)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(50)};
    margin-left: ${props => props.theme.typography.pxToRem(-40)};
  }
`

export const CTA: React.FC<CTAProps> = ({ heading, buttons, content, children, ...props }) => {
  if (buttons) {
    return (
      <CTAButtonContainer
        item
        xs={12}
      >
        <Grid
          container
          direction="row"
          {...props}
        >
          {children}
        </Grid>
      </CTAButtonContainer>
    )
  }

  if (heading) {
    return (
      <CTAHeadingContainer
        { ...props }
      >
        {children}
      </CTAHeadingContainer>
    )
  }

  if (content) {
    return (
      <CTAContentContainer
        { ...props }
      >
        {children}
      </CTAContentContainer>
    )
  }

  return (
    <CTAWrapper
      { ...props }
    >
      {children}
    </CTAWrapper>
  )
}

const CTAContentContainer: React.FC<CTAContentProps> = ({ children, ...props }) => {
  const tablet = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ContentContainer 
      container
      direction={tablet ? 'column' : 'row' }
      alignItems="center"
      justifyContent="flex-start"
      {...props}
    >
      {children}
    </ContentContainer>
  )
}

const CTAHeadingContainer: React.FC<CTAHeadingProps> = ({ children, ...props }) => (
  <HeadingContainer {...props}>
    <span>{children}</span>
  </HeadingContainer>
)

const CTAWrapper: React.FC<CTAWrapperProps> = ({ imageURL, imageAlt, children, ...props }) => (
  <StyledContainer {...props}
  >
    <Image 
      alt={imageAlt}
      src={imageURL}
      layout="fill"
      objectFit="cover"
      quality={100}
    />
    {children}
  </StyledContainer>
)
import * as React from 'react'
export type TriangleProps =  React.SVGProps<SVGSVGElement> & {
  color?: string
}

export const TriangleSvg: React.FC<TriangleProps> = ({ color, ...props }) => {  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 127 127">      
      <g fill="none" >
        <path stroke="none" d="M62.5,0,125,109H0Z"/>
        <path fill={color} d="M 62.5 6.031082153320312 L 5.178375244140625 106 L 119.8216247558594 106 L 62.5 6.031082153320312 M 62.5 0 L 125 109 L 0 109 L 62.5 0 Z"/>
      </g>
    </svg>
  )
}
import React from 'react'
import { gql } from '@apollo/client'
import { IMAGE_FRAGMENT } from '../../../fragments/image'
import { ColumnFeatureFragment } from './__generated__/columnFeature'
import { html } from '../../../utils/html'
import Image from 'next/image'
import { Grid } from '@material-ui/core'


export type ColumnFeatureProps = {
  data: ColumnFeatureFragment[]
}

export const ColumnFeature: React.FC<ColumnFeatureProps> = ({ data }) => {
  return (

    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12} md={4}>
        {html(data[0].leftColumn)}
      </Grid>
      <Grid item xs={12} md={2}/>
      <Grid item md={6}>
        {data[0].rightColumn[0] && data[0].rightColumn[0].__typename === 'images_Asset' && (
          <Image height={1024} width={1024} src={data[0].rightColumn[0].url} alt={''} objectFit="contain" objectPosition="center" />
        )}
      </Grid>
    </Grid>
  )
}

export const COLUMN_FEATURE_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}
  
  fragment ColumnFeatureFragment on columnFeature_BlockType {
    leftColumn
    rightColumn {
      ...ImageFragment
    }
  }
`